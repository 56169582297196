<template>
    <span>
        <button
          class="btn btn-success"
          @click="checkAndUpdateStatus"
          :disabled="checking"
        >
        {{ $t('video.checkAndUpdateStatus') }}
        </button>
        <span class="text-muted" v-if="checking">{{ $t('video.checkingStatusInLivebox') }}...</span>
    </span>
</template>
<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'VideoCheckStatusButton',
  props: {
    video: {
      type: Object
    }
  },
  data () {
    return {
      checking: false
    }
  },
  methods: {
    checkAndUpdateStatus () {
      this.checking = true
      this.getVideoStatus(this.video.liveboxAssetId)
        .then(response => {
          this.getVideoInfo(this.video.liveboxAssetId)
            .then(response => {
              this.video.fileSize = response.data.file_size
              this.video.videoHeight = response.data.video.height
              this.video.videoWidth = response.data.video.width
              this.video.videoDuration = response.data.duration
              this.video.fileName = this.video.liveboxAssetId
              this.$emit('save')
              this.checking = false
            })
            .catch(() => {
              this.checking = false
            })
          if (response.data.status === 'completed') {
            NotifyService.setInfoMessage(
              `${this.$t('video.isComplete')}.`,
              `${this.$t('video.currentStatusInLivebox')}: ${response.data.status}`
            )
            this.video.status = 'live'
            this.$emit('save')
            this.checking = false
            return
          }
          NotifyService.setInfoMessage(
            `${this.$t('video.isNotComplete')}.`,
            `${this.$t('video.currentStatusInLivebox')}: ${response.data.status}`
          )
          this.checking = false
        })
    },
    async getVideoInfo (liveboxAssetId) {
      return new Promise((resolve, reject) => {
        CoreApi().get('/livebox/' + liveboxAssetId + '/video_info')
          .then((response) => {
            resolve({
              data: response.data
            })
          })
          .catch((error) => {
            console.log(error)
            NotifyService.setErrorMessage(`${this.$t('video.unableToGetLiveboxAssetInfo')}.`)
            this.checking = false
          })
      })
    },
    async getVideoStatus (liveboxAssetId) {
      return new Promise((resolve, reject) => {
        CoreApi().get('livebox/' + liveboxAssetId + '/status')
          .then((response) => {
            resolve({
              data: response.data
            })
          })
          .catch((error) => {
            console.log(error)
            NotifyService.setErrorMessage(`${this.$t('video.unableToGetStatusFromLivebox')}.`)
            this.checking = false
          })
      })
    }
  }
}
</script>
