<script>
import VideoNewView from './VideoNewViewOld'
import NotifyService from '../../../services/NotifyService'

export default {
  name: 'VideoEditViewOld',
  extends: VideoNewView,
  methods: {
    async save (redirectToList = false) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.dataLoaded = false
      this.$store.dispatch('video/update', this.prepareRequest(this.video))
        .then(() => {
          this.video = this.$store.getters['video/detail']
          if (this.$store.getters['video/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            if (redirectToList) {
              this.$router.push('/videoOld')
            }
          } else {
            NotifyService.setErrorMessage(this.$store.getters['video/error'])
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    }
  },
  created () {
    this.getVideo()
  }
}
</script>
