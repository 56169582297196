<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('video.safety_topics') }}
      <span class="required">*</span>
    </label><br>
    <template v-for="(safetyTopic, index) in safetyTopics">
      <label
        class="btn btn-secondary m-r-5 m-b-5"
        :class="{ 'active': isSafetyTopicActive(safetyTopic.id) }"
        :data-test="'safety_topic_' + safetyTopic.id"
        :key="`item-${index}`"
      >
        <input
          type="checkbox"
          v-model="video.safetyTopics"
          :value="safetyTopic.id"
          @change="checkSafetyTopics"
        >
        {{ safetyTopic.title }}
      </label>
    </template>
  </section>
</template>

<script>
export default {
  name: 'VideoSafetyTopicsOld',
  props: {
    video: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    safetyTopics () {
      return this.$store.getters['safetyTopic/all']
    }
  },
  methods: {
    isSafetyTopicActive (id) {
      if (this.video.safetyTopics.indexOf(id) > -1) {
        return true
      }
    },
    checkSafetyTopics (event) {
      if (parseInt(event.target.value) === 1) {
        this.video.safetyTopics = [1]
      } else {
        this.video.safetyTopics = this.video.safetyTopics.filter(safetyTopic => safetyTopic > 1)
      }
    }
  }
}
</script>
