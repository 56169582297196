<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('video.video_source') }}
      <span class="required">*</span>
    </label><br>
    <app-checkbox-button
      v-model="video.setting.social"
      id="video_setting_social"
      data-test="video_setting_social"
      :label="$t('video.setting_social')"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="video.setting.agency"
      id="video_setting_agency"
      data-test="video_setting_agency"
      :label="$t('video.setting_agency')"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="video.setting.camera"
      id="video_setting_camera"
      data-test="video_setting_camera"
      :label="$t('video.setting_camera')"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="video.setting.mobilePhone"
      id="video_setting_mobilePhone"
      data-test="video_setting_mobilePhone"
      :label="$t('video.setting_mobile_phone')"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="video.setting.slideshow"
      id="video_setting_slideshow"
      data-test="video_setting_slideshow"
      :label="$t('video.setting_slideshow')"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="video.setting.show"
      id="video_setting_show"
      data-test="video_setting_show"
      :label="$t('video.setting_show')"
    >
    </app-checkbox-button>
  </section>
</template>
<script>
import CheckboxButton from '@/components/form/inputs/InputCheckBox'

export default {
  name: 'VideoSourceTypeOld',
  props: {
    video: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    social () {
      return this.video.setting.social
    },
    agency () {
      return this.video.setting.agency
    },
    camera () {
      return this.video.setting.camera
    },
    mobilePhone () {
      return this.video.setting.mobilePhone
    },
    slideshow () {
      return this.video.setting.slideshow
    },
    show () {
      return this.video.setting.show
    }
  },
  watch: {
    camera (val) {
      if (val) {
        this.video.setting.camera = true
        this.video.setting.mobilePhone = false
        this.video.setting.slideshow = false
        this.video.setting.social = false
        this.video.setting.agency = false
        this.video.setting.show = false
      }
    },
    mobilePhone (val) {
      if (val) {
        this.video.setting.camera = false
        this.video.setting.mobilePhone = true
        this.video.setting.slideshow = false
        this.video.setting.show = false
        this.video.setting.social = false
        this.video.setting.agency = false
      }
    },
    slideshow (val) {
      if (val) {
        this.video.setting.camera = false
        this.video.setting.mobilePhone = false
        this.video.setting.slideshow = true
        this.video.setting.show = false
        this.video.setting.social = false
        this.video.setting.agency = false
      }
    },
    show (val) {
      if (val) {
        this.video.setting.camera = false
        this.video.setting.mobilePhone = false
        this.video.setting.slideshow = false
        this.video.setting.show = true
        this.video.setting.social = false
        this.video.setting.agency = false
      }
    },
    social (val) {
      if (val) {
        this.video.setting.camera = false
        this.video.setting.mobilePhone = false
        this.video.setting.slideshow = false
        this.video.setting.show = false
        this.video.setting.social = true
        this.video.setting.agency = false
      }
    },
    agency (val) {
      if (val) {
        this.video.setting.camera = false
        this.video.setting.mobilePhone = false
        this.video.setting.slideshow = false
        this.video.setting.show = false
        this.video.setting.social = false
        this.video.setting.agency = true
      }
    }
  },
  components: {
    appCheckboxButton: CheckboxButton
  }
}
</script>
